export const environment = {
    production: true,
    region: 'ap-southeast-2',
    endpoint: 'https://staging.api.reali.au',
		wsEndPoint: 'wss://staging.api.reali.au',
    logLevel: 'debug',
    buildId: process.env.NG_APP_BUILD_ID || 'local',
    defaultCollectionLimit:10,
    enableSentry: true,
    defaultTestEmailAddress: "",
version: "staging_782",
		baseDomain: 'staging.reali.au',
};
